import { Location as AngularLocation } from '@angular/common';
import { ApiConfig } from '@config/api.config';
import { ColorHelper } from '@helpers/color.helper';
import { Color } from 'ol/color';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style, { StyleLike } from 'ol/style/Style';
import Text from 'ol/style/Text';

export class OpenlayersHelper {
  static defaultColor = [0, 0, 0];
  static defaultStrokeColor = [255, 255, 255];
  static defaultStrokeWidth = 2;
  static location2: AngularLocation;

  /**
   * Make sure direct links to image assets also include baseHref if needed
   */
  static fixRelativeImageUrlsInKml(kmlUrl: string) {
    if (!OpenlayersHelper.location2 || kmlUrl.startsWith(ApiConfig.imageUrl + '/img')) {
      return kmlUrl;
    }

    const path = location.pathname;
    // if the origin matches but not the current basehref/pathname, rebuild url to match current environment
    if (path?.length > 1 && kmlUrl.includes(location.origin) && !kmlUrl.includes(path)) {
      const relativeUrl = kmlUrl.replace(location.origin, '');
      return location.origin + OpenlayersHelper.location2.prepareExternalUrl(relativeUrl);
    }
    return kmlUrl;
  }

  /**
   * Scale = resolution * inches per map unit (m) * dpi
   * Map scale = 1 : (ScreenRes pixels/inch * 39.37 inches/meter * 156543.04 meters/pixel * cos(latitude) / (2 ^ zoomlevel))
   */
  static getResolution(scale: number) {
    const UNITS_RATIO = 39.37; // inches/meter
    const DPI = 96; // px/inch
    return scale / (UNITS_RATIO * DPI);
  }

  static getSingleStyle(feature: Feature<Geometry>, resolution?: number): Style {
    let styles: StyleLike = feature.getStyle();
    let returnStyle: Style | undefined;
    if (typeof styles === 'function') {
      styles = styles(feature, resolution) as StyleLike;
    }
    if (styles.constructor === Array) {
      returnStyle = styles[0] as Style;
    } else {
      returnStyle = styles as Style;
    }

    return returnStyle;
  }

  static initialize(angularLocation: AngularLocation) {
    OpenlayersHelper.location2 = angularLocation;
  }

  static kmlDefaultStyle() {
    return new Style({
      fill: new Fill({ color: OpenlayersHelper.defaultColor }),
      image: undefined,
      stroke: new Stroke({
        color: OpenlayersHelper.defaultStrokeColor,
        width: OpenlayersHelper.defaultStrokeWidth,
      }),
    });
  }

  static olColor2Hex(color: Color | number[] | string): string {
    return Array.isArray(color) ? ColorHelper.rgb2hex(color[0], color[1], color[2]) : color;
  }

  /**
   * Fix the icon image relative path links
   * @param haystack string openlayers featurecollection in KML format stringified
   * @param prefix
   */
  static prefixRelativeImageUrls(haystack: string, prefix: string = ApiConfig.imageUrl + '/img') {
    return haystack.replace(/<href>\/?img/g, '<href>' + prefix);
  }

  static setStrokeBasedOnFill(textLabel: Text) {
    const fillColor = textLabel.getFill().getColor() as Color;
    const fillAsHcl = ColorHelper.rgb2hcl(fillColor);

    if (!fillColor?.length || !fillAsHcl.length) {
      return;
    }

    textLabel.setStroke(
      new Stroke({
        // If the fill color is light set black stroke, else white
        color: fillAsHcl[2] > 65 ? [0, 0, 0, 1] : [255, 255, 255, 1],
        width: 3,
      }),
    );
  }
}
