import { Color } from 'ol/color';

/**
 * From https://openlayers.org/en/latest/examples/color-manipulation.html
 */
export class ColorHelper {
  /**
   * Color manipulation functions below are adapted from
   * https://github.com/d3/d3-color.
   */
  static readonly nX = 0.95047;
  static readonly nY = 1;
  static readonly nZ = 1.08883;
  static readonly t0 = 4 / 29;
  static readonly t1 = 6 / 29;
  static readonly t2 = 3 * ColorHelper.t1 * ColorHelper.t1;
  static readonly t3 = ColorHelper.t1 * ColorHelper.t1 * ColorHelper.t1;
  static readonly twoPi = 2 * Math.PI;

  /**
   * Convert an HCL pixel into an RGB pixel.
   * @param {Array<number>} pixel A pixel in HCL space.
   * @return {Array<number>} A pixel in RGB space.
   */
  static hcl2rgb(pixel: Color) {
    const converted: Color = [...pixel];
    const h = converted[0];
    const c = converted[1];
    const l = converted[2];

    const a = Math.cos(h) * c;
    const b = Math.sin(h) * c;

    let y = (l + 16) / 116;
    let x = isNaN(a) ? y : y + a / 500;
    let z = isNaN(b) ? y : y - b / 200;

    y = ColorHelper.nY * ColorHelper.lab2xyz(y);
    x = ColorHelper.nX * ColorHelper.lab2xyz(x);
    z = ColorHelper.nZ * ColorHelper.lab2xyz(z);

    converted[0] = ColorHelper.xyz2rgb(3.2404542 * x - 1.5371385 * y - 0.4985314 * z);
    converted[1] = ColorHelper.xyz2rgb(-0.969266 * x + 1.8760108 * y + 0.041556 * z);
    converted[2] = ColorHelper.xyz2rgb(0.0556434 * x - 0.2040259 * y + 1.0572252 * z);

    return converted;
  }

  static lab2xyz(t) {
    return t > ColorHelper.t1 ? t * t * t : ColorHelper.t2 * (t - ColorHelper.t0);
  }

  /**
   * Convert an RGB pixel into an HCL pixel.
   * @param {Array<number>} pixel A pixel in RGB space.
   * @return {Array<number>} A pixel in HCL space.
   */
  static rgb2hcl(pixel: Color) {
    const converted: Color = [...pixel];
    const red = ColorHelper.rgb2xyz(converted[0]);
    const green = ColorHelper.rgb2xyz(converted[1]);
    const blue = ColorHelper.rgb2xyz(converted[2]);

    const x = ColorHelper.xyz2lab((0.4124564 * red + 0.3575761 * green + 0.1804375 * blue) / ColorHelper.nX);
    const y = ColorHelper.xyz2lab((0.2126729 * red + 0.7151522 * green + 0.072175 * blue) / ColorHelper.nY);
    const z = ColorHelper.xyz2lab((0.0193339 * red + 0.119192 * green + 0.9503041 * blue) / ColorHelper.nZ);

    const l = 116 * y - 16;
    const a = 500 * (x - y);
    const b = 200 * (y - z);

    const c = Math.sqrt(a * a + b * b);
    let h = Math.atan2(b, a);
    if (h < 0) {
      h += ColorHelper.twoPi;
    }

    converted[0] = h;
    converted[1] = c;
    converted[2] = l;

    return converted;
  }

  static rgb2hex(r: number, g: number, b: number): string {
    return `#${[r, g, b]
      .map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')}`;
  }

  static rgb2xyz(x) {
    return (x /= 255) <= 0.04045 ? x / 12.92 : Math.pow((x + 0.055) / 1.055, 2.4);
  }

  static xyz2lab(t) {
    return t > ColorHelper.t3 ? Math.pow(t, 1 / 3) : t / ColorHelper.t2 + ColorHelper.t0;
  }

  static xyz2rgb(x) {
    return 255 * (x <= 0.0031308 ? 12.92 * x : 1.055 * Math.pow(x, 1 / 2.4) - 0.055);
  }
}
