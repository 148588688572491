/* eslint-disable perfectionist/sort-classes */
export class AppConfig {
  static readonly DEFAULT_SRID = 25833;

  // How do we want dates formatted/displayed in app
  static readonly FORMAT_DATE = 'dd.MM.yyyy';
  static readonly FORMAT_DATE_TIME = 'dd.MM.yyyy HH:mm';

  static readonly KEY_SIGNIN_RETURNURL = 'signInReturnUrl';

  // Z-indexes to use in OpenLayers
  static readonly ZINDEX_BACKGROUND = 0;
  static readonly ZINDEX_SATELLITE = 4;
  static readonly ZINDEX_ORTHOPHOTO = 5;
  static readonly ZINDEX_THEMELAYERS = 6;
  static readonly ZINDEX_DRAW_SELECT = 80;
  static readonly ZINDEX_DRAW_ORIGIN = 90;
  static readonly ZINDEX_SEARCH = 800;
  static readonly ZINDEX_UPLOAD = 900;
  static readonly ZINDEX_UPLOADED_DRAWING = 920;
  static readonly ZINDEX_REPORT_DRAWINGS = 1000;
  static readonly ZINDEX_USER_DRAWN = 1100;

  static readonly ZOOM_DURATION_MS = 500;
  // Padding to add as option when using map.getView().fit(layer.getSource().getExtent(), {padding: xxx})
  // Clockwise values (t r b l). Top = 100 + header height. Left = 100 + sidebar width.
  static readonly ZOOM_PADDING_MOBILE = [60, 10, 10, 50];
  static readonly ZOOM_PADDING_DESKTOP = [80, 20, 20, 20];
}
