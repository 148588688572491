import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { UserData } from '@models/dto/UserData';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UserService {
  private http = inject(HttpClient);
  private userData = new BehaviorSubject<UserData>(null);

  get userData$(): Observable<UserData> {
    return this.userData.asObservable();
  }

  clearUserData() {
    this.userData.next(null);
  }

  deleteUser() {
    return this.http.delete(ApiConfig.proxiedBackendUrl + 'user');
  }

  editUser(user: UserData) {
    return this.http.put<UserData>(ApiConfig.proxiedBackendUrl + 'user', {
      email: user.email,
      name: user.name,
    });
  }

  refreshUserData() {
    this.http.get<UserData>(ApiConfig.proxiedBackendUrl + 'user').subscribe(
      success => this.setUserData(success),
      err => console.log('Error fetching userdata', err),
    );
  }

  setUserData(ud: UserData) {
    this.userData.next(ud);
  }
}
